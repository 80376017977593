.home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.site-header {
  background-color: #fff;
  border-top: none;
  z-index: 1000;
}

@media (prefers-color-scheme: dark) {
  .site-header {
    background-color: #1a1a1a;
  }
}

.page-content {
  display: flex;
}

.wrapper {
  flex-grow: 1;
}

.site-title {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.pattern {
  z-index: -1;
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='rgb(0 0 0 / 0.1)' viewBox='0 0 32 32'%3E%3Cpath d='M0 .5h31.5V32'/%3E%3C/svg%3E");
  mask-image: radial-gradient(ellipse at center, transparent 0%, #000000);
}

@media (prefers-color-scheme: dark) {
  .pattern {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' stroke='rgb(250 250 250 / 0.05)' viewBox='0 0 32 32'%3E%3Cpath d='M0 .5h31.5V32'/%3E%3C/svg%3E");
  }

}

.post-navigation {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 24px;
}

.intro-header {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    padding: 0;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 8px;
    letter-spacing: -0.02em;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.avatar {
  margin-right: 24px;
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.text-muted {
  color: #484848;
}

@media (prefers-color-scheme: dark) {
  .text-muted {
    color: #b0b0b0;
  }
}

.renderform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .content {
    min-width: 1200px;
    max-width: 1200px;
  }
}

.post-list {

  h2 {
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 4px;
    font-weight: 500;

    .date {
      font-size: 15px;
      font-weight: 500;
      color: var(--minima-brand-color);
      margin-left: 8px;
    }
  }

  li {
    margin-bottom: 16px;
  }
}

.round {
  max-width: 42px !important;
  max-height: 42px !important;
  border-radius: 50%;
  margin-right: 5px;
}

.trigger {
  display: inline;

  a {
    display: inline;
  }
}

.photo-library {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 3px;
    margin-bottom: 8px;
  }


  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  hr {
    border-bottom: 0;
    border-top: 1px solid rgb(128, 128, 128, 0.1);
    border-left: 0;
    border-right: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

}


h1 {
  font-weight: 600;
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;

  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  a {
    font-size: 1.05rem;
  }
}

h4 {
  font-size: 1.0rem;
}

h5 {
  font-size: 1.6rem;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

article {

  .post-title {
    font-size: 2rem;
    margin-bottom: 16px;
  }

  line-height: 1.7;
  font-size: 16px;

  blockquote {
    line-height: 1.7;
    font-size: 16px;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 32px;
  }


  img {
    border-radius: 6px;
  }

  div[class^="language-"] {
    max-width: calc(100vw - 32px);
  }

  .highlight {
    max-width: calc(100vw - 32px);
  }
}

.blog-header {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer {
  z-index: 1000;
  background: #f5fbff !important;
  text-align: center;
  padding: 48px 0;
  border-top: 1px solid #e8e8e8;
}

@media (prefers-color-scheme: dark) {
  footer {
    border-top: 1px solid #484848;
    background: #1a1a1a !important;
  }
}


@media (prefers-color-scheme: dark) {
  :root {
    --minima-background-color: #0a0a0a;
  }

}
